<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card-title class="xflex xflex-row xjustify-between xitems-center xpx-0">
      <Filters
        :value="selectedRange"
        @change="navigate"
        @refetch="fetch(selectedRange)"
        type="custom"
      >
      </Filters>
    </v-card-title>
    <PTOTable
      :loading="loading"
      :loading-more="loading_more"
      :items="items"
      @refetch="fetch(selectedRange)"
      :empty-text="
        selectedType == 'all'
          ? `No PTO's found for selected date range`
          : `No pending PTO's found for selected date range`
      "
    ></PTOTable>
    <v-card-actions
      v-if="next_page_url"
      :class="[mdUp ? 'xabsolute xbottom-0 xh-[60px]' : '']"
      class="xw-full xflex xflex-col xjustify-center xitems-center"
    >
      <btn-tooltip
        tip="Load more"
        color="primary"
        outlined
        @click="fetchMore"
        :loading="loading_more"
        :disabled="loading_more"
      >
        Load more
      </btn-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import Filters from "../components/Filters.vue";
import PTOTable from "../components/PTOTable.vue";
export default {
  data() {
    return {
      loading: false,
      loading_more: false,
      items: [],
      selectedRange: [
        moment().subtract(5, "day").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      next_page_url: null,
    };
  },
  computed: {
    selectedType() {
      return this.$route.name.includes("app-pto-all") ? "all" : "pending";
    },
  },
  mounted() {
    this.$event.$on("refetch-ptos", () => {
      this.fetch({ start: this.selectedRange[0], end: this.selectedRange[1] });
    });
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.selectedRange = val
          ? [val.start, val.end]
          : [
              moment().subtract(5, "day").format("YYYY-MM-DD"),
              moment().format("YYYY-MM-DD"),
            ];
        val && this.fetch(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: `app-pto-${this.selectedType}-custom`,
          params: { start: val[0], end: val[1] },
        })
        .catch(() => {});
    },
    fetch(range) {
      this.loading = true;
      this.$axios
        .get(
          `api/ptos/range/${range.start}/${range.end}?type=${this.selectedType}`
        )
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
  },
  components: { Filters, PTOTable },
};
</script>

<style lang="scss" scoped></style>
